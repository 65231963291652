import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            open: 'open',
            close: 'close',
          }
        }
    }

    init() {

    }

    open(e) {
      const target = e.currentTarget;
      const div = this.$('modal-wrap');
      const html = $(div).html();
      $('#c-modal').find('.c-modal_wrap').html(html);
      $('#c-modal').addClass('is-open');
      if($('#pojo-a11y-toolbar').hasClass('pojo-a11y-toolbar-open')) {
        this.call('toggleAccessibility', null, 'Header');
      }
    }

    close(e) {
      $('#c-modal').removeClass('is-open');
    }
}