import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                close: 'close',
            },
            scroll: {
                scroll: 'toggleScroll'
            }
        }
    }

    init() {
    }

    close() {
        const html = document.querySelector('html');
        const burger = document.querySelector('.c-header_nav_burger');
        const text = burger.querySelector('.-hover');
        html.classList.remove('has-navOpen');
        burger.classList.remove('is-active');
        text.textContent = text.dataset.texthover;
    }

    toggleScroll(){
        const cache = getElementById('navScroll'); 
        if (window.scrollTop() > 100) cache.removeAttribute('data-scroll-section'); 
    }
}
